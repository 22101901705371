import axios from 'axios'
import {useContext} from 'react'
import moment from 'moment'
// import { toast } from "react-toastify";
import {string} from 'yup'
import { Logout } from './auth'
import { log } from 'console'

export const API_ROUTE = 'https://api.alternance.fcpo.agency'
// const API_ROUTE = 'http://127.0.0.1:8000'

// const API_ROUTE =process.env.API_URL

interface callApiI {
  route: string
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  requiresAuth?: boolean
  body?: any
  formData?: boolean
}

export const callApi = async ({route, method, requiresAuth = false, body, formData}: callApiI) => {
  //  try {
  //     const headers: any = requiresAuth
  //       ? {
  //           Authorization: `Bearer ${getLocalUser().token}`,
  //         }
  //       : null;
  //     let response: any;
  //     const r = `${API_ROUTE}/${route}`;
  //     console.log(r);
  //     if (method === "POST") {
  //       response = await axios.post(r, body, {
  //         headers,
  //       });
  //     } else if (method === "GET") {
  //       response = await axios.get(r, {
  //         headers,
  //       });
  //     } else if (method === "DELETE") {
  //       response = await axios.delete(r, {
  //         headers,
  //       });
  //     } else if (method === "PUT") {
  //       response = await axios.put(r, body, {
  //         headers,
  //       });
  //     }
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
}

const isFile = (input: any) => 'File' in window && input instanceof File
const isFileList = (input: any) => 'File' in window && input instanceof FileList

export function useCallApi() {
  const callApi = async ({route, method, requiresAuth = false, body, formData}: callApiI) => {
    let dataToSend: any = body

    try {
      let response: any

      const r = `${API_ROUTE}/${route}`

      if (method === 'POST') {
        response = await axios.post(r, dataToSend, {})
      } else if (method === 'GET') {
        response = await axios.get(r, {})
      } else if (method === 'DELETE') {
        response = await axios.delete(r, {})
      } else if (method === 'PUT') {
        response = await axios.put(r, dataToSend, {})
      } else if (method === 'PATCH') {
        response = await axios.patch(r, dataToSend, {})
      }

      return response
    } catch (error:any) {
      // console.log(error.response.status === 401);
      
     
        throw error
      
     
    }
  }

  return callApi
}

export function createFormData(data: any, files?: any) {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  if (files) {
    files.map((file: any) => {
      formData.append('images[]', file)
    })
  }

  return formData
}

const encodeHtmlAssociations: {[key: string]: string} = {
  ['<']: '@lt',
  ['>']: '@gt',
}
const decodeHtmlAssociations: {[key: string]: string} = {
  ['@lt']: '<',
  ['@gt']: '>',
}
export function encodeHtmlTags(html: string): string {
  Object.keys(encodeHtmlAssociations).forEach((key: string) => {
    html = html.replace(new RegExp(key, 'g'), encodeHtmlAssociations[key])
  })
  return html
}

export function decodeHtmlTags(html: any): any {
  Object.keys(decodeHtmlAssociations).forEach((key: string) => {
    html = html.replace(new RegExp(key, 'g'), decodeHtmlAssociations[key])
  })
  return html
}

export const getDate = (date: any) => {
  return moment(date).format('MM/DD/YYYY')
}

export const getMoneyAmount = (amount: number) => {
  return `${amount} EUR`
}

// export const showToast = (
//   message: string | string[],
//   type: "info" | "success" | "warning" | "error" | "default"
// ) => {
//   toast(message, {
//     type,
//     hideProgressBar: true,
//   });
// };

export const getUserPhoto = (photo: any) => {
  return photo
    ? `${process.env.NEXT_PUBLIC_STORAGE_ROUTE}/${photo}`
    : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
}

export const getRangeYears = () => {
  const currentYear = new Date().getFullYear()
  const years = []

  let startYear = currentYear - 50

  while (startYear <= currentYear + 50) {
    years.push(startYear++)
  }

  return years
}
