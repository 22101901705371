import React, { useEffect, useState } from 'react';
import { useCallApi } from '../../modules/utils';
import { ToastContainer, toast } from 'react-toastify';
import { useAuth } from '../../modules/auth';
import { ItemSaleInvoiced } from './ItemSaleInvoiced';
import { Pagination } from '@mui/material'; // Assuming you're using Material-UI for pagination
import FormBN from './FormBN';

export const ListSaleInvoiced = () => {
  const { clients } = useAuth();

  const [subventes, setSubventes] = useState<any[]>([]);
  const [date, setDate] = useState<string>('');
  const [client, setClient] = useState<any>('');

  const [loading, setLoading] = useState(true);
  const [itemsSelected, setItemsSelected] = useState<number>(0);
  const [checkedProductIds, setCheckedProductIds] = useState<any[]>([]);
  const [filterSubventes, setFilterSubventes] = useState<any[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10; // Fixed items per page

  const callApi = useCallApi();

  const getSubventes = async () => {
    try {
      setLoading(true);
      const { data } = await callApi({
        route: `api/sale_invoiceds?filter[customer_id]=${client}&filter[date]=${date}`,
        method: 'GET',
      });

      const initialSubventes = data.data.map((item: any, index: number) => ({
        ...item,
        checked: false,
        num: index + 1,
      }));

      setSubventes(initialSubventes);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedSubventes = filterSubventes.map((product: any) => ({
      ...product,
      checked,
    }));
    if (checked) {
      const checkedProductIds = updatedSubventes.map((product: any) => product.id);
      setItemsSelected(checkedProductIds.length);
    } else {
      setItemsSelected(0);
    }
    const checkedIds = updatedSubventes
      .filter((product: any) => product.checked)
      .map((product: any) => product.id);
    setCheckedProductIds(checkedIds);
    setFilterSubventes(updatedSubventes);
  };

  const handleProductCheckboxChange = (productId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const updatedSubventes = filterSubventes.map((product: any) =>
      product.id === productId ? { ...product, checked } : product
    );
    setFilterSubventes(updatedSubventes);
    const checkedIds = updatedSubventes
      .filter((product: any) => product.checked)
      .map((product: any) => product.id);
    setCheckedProductIds(checkedIds);
    setItemsSelected(checkedIds.length);
  };

  const deleteProduct = async (ids: number[]) => {
    if (window.confirm('Voulez-vous vraiment supprimer cette vente !')) {
      ids.forEach(async (id: number) => {
        const filteredPosts = subventes.filter((product: any) => product.id !== id);
        setFilterSubventes(filteredPosts);
        toast.success('Vente supprimée');
        await callApi({
          route: 'api/sale_invoiceds/' + id,
          method: 'DELETE',
        });
      });
    }
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getSubventes();
  }, [date, client]);

  useEffect(() => {
    setFilterSubventes(subventes.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
  }, [subventes, currentPage]);

  return (
    <>
      <ToastContainer />
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Liste des Bons de livraison</span>
            
              
          </h3>

         
          <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
         
            {itemsSelected !== 0 ? (
              <div className='d-flex justify-content-end align-items-center'>
                <div className='fw-bolder me-5'>
                  <span className='me-2'>{itemsSelected}</span> Selectionnées
                </div>
                <button
                  type='button'
                  className='btn btn-sm btn-danger'
                  onClick={() => deleteProduct(checkedProductIds)}
                >
                  Supprimer les sélectionnés
                </button>
              </div>
            ) : (
              <>
               <div className='col me-3'>
              
                </div> 
            
                <div className='col me-3'>
                  <label className='fs-7 text-gray-500 fw-bold'>Date facture:</label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
                <div className='col'>
                  <label className='fs-7 text-gray-500 fw-bold mb-1'>Client:</label>
                  <select
                    className='form-select form-select-solid'
                    onChange={(e) => setClient(e.target.value)}
                  >
                    <option value=''>choisir..</option>
                    {clients &&
                      clients.map((client: any, index: number) => (
                        <option key={index} value={client.id}>
                          {client.attributes.name}
                        </option>
                      ))}
                  </select>
                </div>
               
              </>
            )}
          </div>
        </div>
        {loading ? (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        ) : (
          <div className='card-body py-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>
                      <div className='form-check form-check-custom form-check-solid mx-5'>
                        <input
                          type='checkbox'
                          className='form-check-input'
                          checked={filterSubventes.every((product: any) => product.checked)}
                          onChange={handleHeaderCheckboxChange}
                        />
                      </div>
                    </th>
                    <th className='text-start'>Numéro</th>
                    <th className='text-start'>Date</th>
                    <th className='text-start'>Client</th>
                    <th className='text-start'>Total</th>
                    <th className='text-start'>Statut</th>
                    <th className='text-end'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filterSubventes.map((product: any, index: any) => (
                    <ItemSaleInvoiced
                      key={index}
                      data={product}
                      handleProductCheckboxChange={handleProductCheckboxChange}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              count={Math.ceil(subventes.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color='primary'
              className='d-flex justify-content-center'
            />
          </div>
        )}
      </div>
    </>
  );
};
